.ais-InfiniteHits-item {
  padding: 0 !important;
}

.ais-InfiniteHits-item:hover {
  background-color: rgb(250, 250, 250);
  cursor: pointer;
}

a {
  color: black !important;
}

a:hover {
  color: initial !important;
  text-decoration: none !important;
}

/* Making category list stay on screen while scrolling */
.ais-RefinementList {
  position: sticky;
  top: 70px;
  margin-right: 30px;
}

/* Making search bar stay on screen while scrolling */
.ais-SearchBox {
  position: sticky;
  top: 59px;
  z-index: 9001;
}
